<template>
    <div>
        <div style="height: 20px;"></div>
        <!--详情-->
        <el-row>
            <el-col :span="16">
                <el-form v-loading="loading" size="medium" ref="form" :model="form"
                         :label-width="this.env.label_width">
                    <el-form-item label="券包名称">
                        <el-input v-model="form.coupon_bale_title"></el-input>
                    </el-form-item>
                    <el-form-item label="券包封面">
                        <span class="form-tip">不设置将使用默认封面</span>
                        <Qnupload v-model="form.cover" :sum="1" :isclipper="true" :fixedNumber="[17,5]" />
                        <div class="form-tip">图片尺寸1020*300  或 宽:高≈17:5</div>
                    </el-form-item>
                    <el-form-item label="券包价格(￥)">
                        <el-input-number v-model="form.price" :step="0.01" :min="0"
                                         :max="999999"
                                         step-strictly
                        ></el-input-number>
                        <br><span class="form-tip">售卖价格</span>
                    </el-form-item>
                    <el-form-item label="优惠券">
                        <el-select
                                v-model="form.coupon_s"
                                multiple
                                filterable
                                placeholder="请选择优惠券"
                                remote
                                :remote-method="get_coupon_list"
                                :loading="select_loading"
                                style="width: 100%">
                            <el-option
                                    v-for="item in coupon_list"
                                    :key="item.coupon_uuid"
                                    :label="item.coupon_title"
                                    :value="item.coupon_uuid">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="券包规则">
                        <el-input v-model="form.bale_rule" type="textarea" :autosize="{ minRows: 4, maxRows: 6} "
                                  maxlength="2000" show-word-limit></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button v-if="is_auth('coupon.bale.isadd')"
                                   size="medium" type="primary" @click="save">保存
                        </el-button>
                        <el-button size="medium" @click="isreturn">返回</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Qnupload from '@/components/Qnupload'

export default {
    components: {
        Qnupload,
    },
    data() {
        return {
            loading: false,
            select_loading: false,
            coupon_list: [],                    // 优惠券列表

            form: {
                coupon_bale_title: '',          // 券包名称
                cover: '',                      // 券包封面
                price: 0,                       // 券包价格
                coupon_s: [],                   // 可用优惠券
                bale_rule: '',                  // 券包规则
            },
        }
    },
    // 创建
    created() {
        this.get_coupon_list(null)          // 获取可用优惠券列表
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 获取优惠券列表
        get_coupon_list(key) {
            if (key === '') return false;
            let postdata = {
                api_name: 'coupon.bale.getusablecoupon',
                token: this.Tool.get_l_cache('token'),
                key,
            }
            this.select_loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.select_loading = false
                if (json.code === 0) {
                    this.coupon_list = json.data
                }
            })
        },
        //保存
        save() {
            let postdata = {
                api_name: "coupon.bale.isadd",
                token: this.Tool.get_l_cache('token'),
            }
            Object.assign(postdata, this.form);

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.isreturn();        // 返回到列表
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            this.$router.go(-1)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.appoint {
    border-left: 10px solid #409EFF;
}
</style>
